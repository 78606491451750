export default () => {

  $(document).ready(function () {
    if (window.location.pathname !== '/' && window.location.pathname !== '/marketplace') return
    console.log('Marketplace function')
    function formatCurrency(num) {
      var currencyNum = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(num);
      return currencyNum.replace(/\s€/, '');
  }
  
  function calculateLowestOfferPrice(asset) {
    var offers = asset.offers.filter(offer => offer.type === 'sell').filter(offer => offer.status === 'open').sort((a, b) => (a.price > b.price ? 1 : -1));
    return offers.length ? offers[0].price : asset.sharePrice
  }

  async function assetsRender() {
    
    await fetch('https://api.app.brickwise.at/api/v1/assets')
      .then(function (response) { return response.json() })
      .then(function (data) {

        for (var asset of data) {
          var rentalReturn = new Intl.NumberFormat('de-DE').format(asset.rentalReturn) + ' %'
          var marketValue = '€ ' + formatCurrency(asset.sharePrice * asset.bricks)
          var availableShares = asset.stage === 'primary' ? asset.availableBricks : asset.availableBricks + asset.availableBricksSecondary
          var sharePrice = asset.stage === 'primary' ? '€ ' + formatCurrency(asset.sharePrice) : 'ab € ' + formatCurrency(calculateLowestOfferPrice(asset))
        
          var rentalReturnHTML = document.getElementById(`${asset.id.toLowerCase()}_rentalReturn`)
          var rentalReturnHTMLMob = document.getElementById(`${asset.id.toLowerCase()}_rentalReturn_mob`)
          var marketValueHTML = document.getElementById(`${asset.id.toLowerCase()}_marketValue`)
          var marketValueHTMLMob = document.getElementById(`${asset.id.toLowerCase()}_marketValue_mob`)
          var availableSharesHTML = document.getElementById(`${asset.id.toLowerCase()}_availableShares`)
          var availableSharesHTMLMob = document.getElementById(`${asset.id.toLowerCase()}_availableShares_mob`)
          var sharePriceHTML = document.getElementById(`${asset.id.toLowerCase()}_sharePrice`)
          var sharePriceHTMLMob = document.getElementById(`${asset.id.toLowerCase()}_sharePrice_mob`)
          
          if (rentalReturnHTML) rentalReturnHTML.innerHTML = rentalReturn
          if (rentalReturnHTMLMob) rentalReturnHTMLMob.innerHTML = rentalReturn
          if (marketValueHTML) marketValueHTML.innerHTML = marketValue
          if (marketValueHTMLMob) marketValueHTMLMob.innerHTML = marketValue
          if (availableSharesHTML) availableSharesHTML.innerHTML = availableShares
          if (availableSharesHTMLMob) availableSharesHTMLMob.innerHTML = availableShares
          if (sharePriceHTML) sharePriceHTML.innerHTML = sharePrice
          if (sharePriceHTMLMob) sharePriceHTMLMob.innerHTML = sharePrice 
        }

          return data;
      })
      .catch(function (error) {
          console.error('Error:', error);
      });
    }
    assetsRender();

  });
};