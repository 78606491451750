export default () => {

  $(document).ready(function () {
    console.log("Loaded function")
    $("#btn-affiliate-copy").click(function () {
      console.log("Clicked on element")
      var affiliateLink = document.getElementById("link-affiliate").innerHTML;
      navigator.clipboard.writeText(affiliateLink);
  
      document.getElementById("txt-affiliate-success").innerHTML = "Der Link wurde in die Zwischenablage kopiert"
      document.getElementById("div-affiliate-success").style.display = "block";
  
      setTimeout(() => { document.getElementById("div-affiliate-success").style.display = "none"; }, 3000)
    });
  });
};
