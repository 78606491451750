export default () => {

  $(document).ready(function () {
    if (!window.location.pathname.includes("/assets/")) return
    console.log('Loaded Asset Script 7')

    var TContractTypeGermanConverter = {
      open: 'unbefristet',
      closed: 'befristet'
    }

    var TBuildingConditionGermanConverter = {
      old: 'Altbau',
      new: 'Neubau',
      massive: 'Massiv'
    }

    var THeatingTypeGermanConverter = {
      central: 'Zentralheizung',
      district: 'Fernwärme',
      floorCentral: 'Etagenheizung'
    }

    var TPropertyTypeGermanConverter = {
      flat: 'Wohnung',
      commercial: 'Gewerbe',
      familyHome: 'Einfamilienhaus',
      partialFamilyHome: 'Haus (Miteigentum)'
    }

    var TDocumentNames = {
      investmentBasicInformation: 'Basisinformationsblatt',
      investmentInformation: 'Informationsblatt',
      investmentConditions: 'Investitionsbedingungen',
      rentalAgreement: 'Mietvertrag',
      expertProtocol: 'Sachverständigenprotokoll',
      landRegister: 'Grundbruchauszug',
      energyCertificate: 'Energieausweis',
      utilityReport: 'Nutzwertgutachten'
    }

    var t = [{
      featureType: "water",
      elementType: "geometry",
      stylers: [{
        color: "#e9e9e9"
      }, {
        lightness: 17
      }]
    }, {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{
        color: "#f5f5f5"
      }, {
        lightness: 20
      }]
    }, {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [{
        color: "#ffffff"
      }, {
        lightness: 17
      }]
    }, {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{
        color: "#ffffff"
      }, {
        lightness: 29
      }, {
        weight: .2
      }]
    }, {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [{
        color: "#ffffff"
      }, {
        lightness: 18
      }]
    }, {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [{
        color: "#ffffff"
      }, {
        lightness: 16
      }]
    }, {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{
        color: "#f5f5f5"
      }, {
        lightness: 21
      }]
    }, {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{
        color: "#dedede"
      }, {
        lightness: 21
      }]
    }, {
      elementType: "labels.text.stroke",
      stylers: [{
        visibility: "on"
      }, {
        color: "#ffffff"
      }, {
        lightness: 16
      }]
    }, {
      elementType: "labels.text.fill",
      stylers: [{
        saturation: 36
      }, {
        color: "#333333"
      }, {
        lightness: 40
      }]
    }, {
      elementType: "labels.icon",
      stylers: [{
        visibility: "off"
      }]
    }, {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{
        color: "#f2f2f2"
      }, {
        lightness: 19
      }]
    }, {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [{
        color: "#fefefe"
      }, {
        lightness: 20
      }]
    }, {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [{
        color: "#fefefe"
      }, {
        lightness: 17
      }, {
        weight: 1.2
      }]
    }];

    function e(t) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR"
      }).format(t).replace(/\s€/, "")
    }

    function i(t) {
      $(".section-cta").find(".cta-title > .bold-text-20,.cta-overview-top > div:nth-child(1) > p:nth-child(2) strong,.cta-overview-top > div:nth-child(2) > p:nth-child(2) strong,.cta-overview-top > div:nth-child(3) > p:nth-child(2) strong,.cta-overview-top > div:nth-child(4) > p:nth-child(2) strong,.cta-overview-bottom > div:nth-child(1) > p:nth-child(2) strong,.cta-overview-bottom > div:nth-child(2) > p:nth-child(2) strong,.cta-overview-bottom > div:nth-child(3) > p:nth-child(2) strong,.cta-overview-bottom > div:nth-child(4) > p:nth-child(2) strong,.cta-slider").css(t), $(".asset-detail-data").css(t), $(".asset-static-map,.behind-the-bricks-text").css(t)
    } !async function () {
      var path = window.location.pathname.split("/"),
        assetId = path[path.length - 1].toUpperCase(),
        launchpadAssetUrl = "https://bwrealestate.app.brickwise.at/api/v1/asset/" + assetId + "?newFormat=true",
        middlewareAssetUrl = "https://api.app.brickwise.at/api/v1/assets?assetId=" + assetId;
      i({
        filter: "blur(8px)"
      }), await fetch(launchpadAssetUrl).then(async function (launchpadAssetRequest) {
        var launchpadAsset = await launchpadAssetRequest.json(),
          r = $(".section-cta");
        r.find(".cta-title > .bold-text-20").text(launchpadAsset.descriptions.title), r.find(".cta-overview-top > div:nth-child(1) > p:nth-child(2) strong").text(new Intl.NumberFormat("de-DE").format(Number(launchpadAsset.rentalDetails.rentalReturn.$numberDecimal)) + "% p.a.");
        var marketValue = Number(launchpadAsset.investmentDetails.sharePrice.$numberDecimal) * launchpadAsset.investmentDetails.totalBricks;
        r.find(".cta-overview-top > div:nth-child(2) > p:nth-child(2) strong").text("€ " + e(marketValue)), r.find(".cta-overview-top > div:nth-child(3) > p:nth-child(2) strong").text("€ " + e(Number(launchpadAsset.investmentDetails.sharePrice.$numberDecimal))), r.find(".cta-overview-bottom > div:nth-child(1) > p:nth-child(2) strong").text(TPropertyTypeGermanConverter[launchpadAsset.propertyDetails.propertyType]), r.find(".cta-overview-bottom > div:nth-child(2) > p:nth-child(2) strong").text(TBuildingConditionGermanConverter[launchpadAsset.propertyDetails.buildingCondition]);
        var o = new Intl.NumberFormat("de-DE").format(parseFloat(launchpadAsset.propertyDetails.size));
        r.find(".cta-overview-bottom > div:nth-child(3) > p:nth-child(2) strong").text(o + "m²"), r.find(".cta-overview-bottom > div:nth-child(4) > p:nth-child(2) strong").text(TContractTypeGermanConverter[launchpadAsset.rentalDetails.contractType]);
        var availableBricks = 0;
        await fetch(middlewareAssetUrl).then(async function (middlewareAssetRequest) {
          for (var e = await middlewareAssetRequest.json(), i = e[0], n = 0; n < e.length; n++)
            if (e[n].id === assetId) {
              i = e[n];
              break
            }
          availableBricks = i.stage === 'primary' ? 0 : i.availableBricksSecondary
        }).catch(function (t) {
          console.error("Error: " + t)
        });
        var c = launchpadAsset.investmentDetails.availableBricks + availableBricks;
        if (r.find(".cta-overview-top > div:nth-child(4) > p:nth-child(2) strong").text(c),launchpadAsset.images.propertyPhotos.length)
          for (var h = $(".cta-slider"), f = 0; f < 10; f++) {
            var p = f % launchpadAsset.images.propertyPhotos.length,
              v = h.find(".w-slide:nth-child(" + (f + 1) + ")").find("img");
            v.attr("src", launchpadAsset.images.propertyPhotos[p].src), v.removeAttr("srcset"), v.removeAttr("sizes")
          }
        $(".behind-the-bricks-text").text(launchpadAsset.descriptions.propertyDetails);
        var m = $("#asset-dynamic-map"),
          g = $(".asset-static-map");
        m.show(), g.hide();
        var y = {
          lat: launchpadAsset.location.coordinates.lat,
          lng: launchpadAsset.location.coordinates.lng
        },
          b = document.createElement("script");
        b.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBQJ_8CWPikHS9mKokj6WcRQgmNKHPnBM8&callback=brickwiseMap", b.async = !0, window.brickwiseMap = function () {
          var e = new google.maps.Map(document.getElementById("asset-dynamic-map"), {
            center: y,
            zoom: 15,
            styles: t
          });
          new google.maps.Marker({
            position: y,
            map: e
          })
        }, document.head.appendChild(b);
        var x = $(".asset-detail-data"),
          w = x.find("img");
        w.removeAttr("srcset"), w.removeAttr("sizes"), x.find(".detail-real-estate-info .info-text").text(launchpadAsset.descriptions.propertyDetails);
        var u = x.find(".info-data");
        u.find("div:nth-child(1) > p:nth-child(2) > strong").text("€ " + e(marketValue));
        var D = parseFloat(launchpadAsset.propertyDetails.size),
          k = marketValue / D;
        u.find("div:nth-child(2) > p:nth-child(2) > strong").text("€ " + e(k)), u.find("div:nth-child(3) > p:nth-child(2) > strong").text(e(D) + "m²"), u.find("div:nth-child(4) > p:nth-child(2) > strong").text(launchpadAsset.propertyDetails.buildingType), u.find("div:nth-child(5) > p:nth-child(2) > strong").text(launchpadAsset.propertyDetails.numberOfRooms + launchpadAsset.propertyDetails.numberOfRooms === 1 ? "Raum" : "Räume"), u.find("div:nth-child(6) > p:nth-child(2) > strong").text(TBuildingConditionGermanConverter[launchpadAsset.propertyDetails.buildingCondition]), u.find("div:nth-child(7) > p:nth-child(2) > strong").text(Number(launchpadAsset.location.floor) === 0 ? "Erdgeschoss" : launchpadAsset.location.floor), u.find("div:nth-child(8) > p:nth-child(2) > strong").text(THeatingTypeGermanConverter[launchpadAsset.propertyDetails.heatingType]), x.find(".furnishing").html(""), x.find(".info-quote-text").text(launchpadAsset.descriptions.propertyHighlight), x.find("img.floor-plan").attr("src", launchpadAsset.images.floorPlans.length >= 1 ? launchpadAsset.images.floorPlans[0].src : '' ), x.find(".info-2nd-text").text(launchpadAsset.descriptions.propertySellingPoints);
        var T = x.find(".detail-location div:nth-child(2)");
        T.find("div:nth-child(1)").text(launchpadAsset.descriptions.locationDetails), T.find("img.location-image").attr("src", launchpadAsset.images.locationPhotos.length >= 1 ? launchpadAsset.images.locationPhotos[0].src : ''), T.find("div:last-child > div:nth-child(1)").html("<p>" + launchpadAsset.descriptions.districtDetails + "</p><p>" + launchpadAsset.descriptions.cityDetails + "</p>");
        var j = x.find(".detail-investment-info");
        j.find(".invest-1st-text").text(launchpadAsset.descriptions.investmentDetails);
        var P = j.find(".investment-data-top");
        P.find("div:nth-child(1) > p:nth-child(2) > strong").text(launchpadAsset.investmentDetails.totalBricks), P.find("div:nth-child(2) > p:nth-child(2) > strong").text(c), P.find("div:nth-child(3) > p:nth-child(2) > strong").text("€ " + e(Number(launchpadAsset.investmentDetails.sharePrice.$numberDecimal))), P.find("div:nth-child(4) > p:nth-child(2) > strong").text(e(Number(launchpadAsset.rentalDetails.rentalReturn.$numberDecimal)) + "%");
        var A = j.find(".investment-data-bottom");
        A.find("div:nth-child(1) > p:nth-child(2) > strong").text(launchpadAsset.rentalDetails.monthlyDistributionDate), j.find(".investment-isin-text").text(launchpadAsset.investmentDetails.ISIN), j.find(".investment-blockchain-link").attr("href", `https://explorer.brickprotocol.com/tx/${launchpadAsset.tokenId}`);
        var N = x.find(".detail-rental-overview > div:nth-child(2)");
        N.find(".rental-1st-text").text(launchpadAsset.descriptions.rentalDetails), N.find(".rental-yield").text(e(Number(launchpadAsset.rentalDetails.rentalReturn.$numberDecimal)) + "%"), N.find(".rental-net-income").text("€ " + e(Number(launchpadAsset.rentalDetails.rentalIncome.$numberDecimal))), N.find(".rentail-maintenance-cost").text("€ " + e(Number(launchpadAsset.rentalDetails.liquidityReserve.$numberDecimal))), N.find(".rental-management-cost").text("€ " + e(Number(launchpadAsset.rentalDetails.managementFee.$numberDecimal))), N.find(".rental-net-income-2").text("€ " + e(Number(launchpadAsset.rentalDetails.rentalSurplus.$numberDecimal))), N.find(".rental-level-percent").text(e(launchpadAsset.rentalDetails.occupancyRate) + "%");
        var E = x.find(".detail-imprint > div:nth-child(2)");
        E.find(".imprint-company-name > strong").html(launchpadAsset.investmentDetails.spv.companyName + "<br/>");
        var I = launchpadAsset.investmentDetails.spv.address;
        E.find(".imprint-address").html('<div class="text-block-10 sw gt">' + I.street + ", " + I.postCode + " " + I.city + ", " + I.country + '<br></div><div class="text-block-10 sw gt">Firmenbuch:<br></div><div class="text-block-10 sw gt">Geschäftsführer: ' + launchpadAsset.investmentDetails.spv.owner + '<br></div><div class="text-block-10 sw gt">E-mail: ' + launchpadAsset.investmentDetails.spv.email + "<br></div>"), E.find(".imprint-company-object").text(launchpadAsset.investmentDetails.spv.companyPurpose), $(".asset-downloads").remove();
        var R = "";
        for (var doc in launchpadAsset.documents) {
          var value = launchpadAsset.documents[doc]
          if (typeof value === 'string' || value instanceof String) {
            R += '<div class="asset-downloads"><a href="' +
            value +
            '" target="_blank" class="link-block-18 w-inline-block"><p class="paragraph-30 w-clearfix">' +
            TDocumentNames[doc] +
            ' <span class="text-span-3"></span></p></a></div>'
          }
        }
        $(R).insertAfter(".download-label")
      }).catch(function (t) {
        console.error("Error: " + t)
      }), i({
        filter: "none"
      })
    }()
  });
};
