import legaltexts from "./legaltexts";
import formHandling from "./formHandling";
import buttonHandling from "./buttonHandling";
import assetPageHandling from "./assetPages"
import assetMarketplace from "./assetMarketplace";

(() => {
  console.log("loaded script");
  $(document).ready(function () {
    $(document).on("click", "[data-gatrack]", function (e) {
      var $link = $(this);
      var commaSeparatedEventData = $link.data("gatrack");
      var eventParams = commaSeparatedEventData.split(",");

      if (!eventParams) {
        return;
      }

      eventCategory = eventParams[0];
      eventAction = eventParams[1];
      eventLabel = eventParams[2];

      dataLayer.push({
        event: eventAction,
        eventCategory: eventCategory,
        eventLabel: eventLabel,
      });
    });
    Webflow.push(function () {
      // new form handling
      formHandling();
      buttonHandling();
      assetPageHandling();
      assetMarketplace();
    });

    legaltexts();
  });
})();
