export default (() => {
  // Instructions
  // The click span that triggers the tooltip needs to have class: legal-tooltip-span-1 and data-tooltip="oneA" (or any other number of them).
  // Use Legal-Icon class to get the icon.
  // Edit the code here: https://gitlab.com/brickwise-labs/webflow-legal-texts
  const e = {
      oneA: {
        sm: "<span>Digitale Immobilienanteile sind tokenisierte Genussscheine, die dir das Recht zur Partizipation an Mietüberschüssen aus der Vermietung und dem Erlös aus der Veräußerung der Immobilie einräumen. Grundbücherliches Eigentum wird nicht begründet. Nach der Emission wird jedoch ein grundbücherliches Pfandrecht in Höhe des Ausgabepreises für alle Inhaber:innen digitaler Immobilienanteile beantragt.</span>",
      },
      oneB: {
        sm: "<span>Anteile ist die Kurzbezeichnung für digitale Immobilienanteile, die du über Brickwise handeln kannst. Digitale Immobilienanteile sind tokenisierte Genussscheine, die dir das Recht zur Partizipation an Mietüberschüssen aus der Vermietung und dem Erlös aus der Veräußerung der Immobilie einräumen.</span>",
        xl: "<span>Anteile ist die Kurzbezeichnung für digitale Immobilienanteile, die du über Brickwise handeln kannst. Digitale Immobilienanteile sind tokenisierte Genussscheine, die dir das Recht zur Partizipation an Mietüberschüssen aus der Vermietung und dem Erlös aus der Veräußerung der Immobilie einräumen. Grundbücherliches Eigentum wird nicht begründet. Nach der Emission wird jedoch ein grundbücherliches Pfandrecht in Höhe des Ausgabepreises für alle Inhaber:innen digitaler Immobilienanteile beantragt.</span>",
      },
      oneC: {
        sm: "<span>Bei Brickwise investierst du nicht direkt in Immobilien, sondern erwirbst digitale Immobilienanteile. Digitale Immobilienanteile sind tokenisierte Genussscheine, die dir das Recht zur Partizipation an Mietüberschüssen aus der Vermietung und dem Erlös aus der Veräußerung der Immobilie einräumen.</span>",
        xl: "<span>Bei Brickwise investierst du nicht direkt in Immobilien, sondern erwirbst digitale Immobilienanteile. Digitale Immobilienanteile sind tokenisierte Genussscheine, die dir das Recht zur Partizipation an Mietüberschüssen aus der Vermietung und dem Erlös aus der Veräußerung der Immobilie einräumen. Grundbücherliches Eigentum wird nicht begründet. Nach der Emission wird jedoch ein grundbücherliches Pfandrecht in Höhe des Ausgabepreises für alle Inhaber:innen digitaler Immobilienanteile beantragt.</span>",
      },
      oneD: {
        sm: "<span>Die grundbücherliche Sicherheit ist ein im Grundbuch eingetragenes Kollektivpfandrecht zugunsten der Inhaber:innen der tokenisierten Genussscheine in Höhe des Ausgabepreises (Nominale) des tokenisierten Genussscheins. Der Antrag auf grundbücherliche Einverleibung des Pfandrechts wird von der Emittentin unmittelbar nach Erwerb der Immobilie gestellt.</span>",
      },
      twoA: {
        sm: '<span>Dein "Anteil der Miete" ist ein schuldrechtlicher Anspruch gegenüber der Emittentin auf anteilige Ausschüttung der monatlichen Überschüsse aus der Vermietung der Immobilie. Als Investor:in bist du also selbst nicht Vermieter:in, diese Rolle und die damit verbundenen Aufgaben liegen bei der Emittentin.</span>',
        xl: '<span>Dein "Anteil der Miete" ist ein schuldrechtlicher Anspruch gegenüber der Emittentin auf anteilige Ausschüttung der monatlichen Überschüsse aus der Vermietung der Immobilie. Als Investor:in bist du also selbst nicht Vermieter:in, diese Rolle und die damit verbundenen Aufgaben liegen bei der Emittentin. Die angegebene Mietrendite errechnet sich auf Basis der im Mietvertrag zur entsprechenden Immobilie vereinbarten Miete, abzüglich etwaiger Rücklagen, einer Management Fee, einer Liquiditätsrücklage und gegebenenfalls sonstigen zur ordentlichen Verwaltung der jeweiligen Immobilie notwendigen Maßnahmen und Kosten. Die Mietrenditen sind in Prozent pro Jahr angegeben.</span>',
      },
      twoB: {
        sm: '<span>Dein "Teil der Miete" ist ein schuldrechtlicher Anspruch gegenüber der Emittentin auf anteilige Ausschüttung der monatlichen Überschüsse aus der Vermietung der Immobilie. Als Investor:in bist du also selbst nicht Vermieter:in, diese Rolle und die damit verbundenen Aufgaben liegen bei der Emittentin.</span>',
        xl: '<span>Dein "Teil der Miete" ist ein schuldrechtlicher Anspruch gegenüber der Emittentin auf anteilige Ausschüttung der monatlichen Überschüsse aus der Vermietung der Immobilie. Als Investor:in bist du also selbst nicht Vermieter:in, diese Rolle und die damit verbundenen Aufgaben liegen bei der Emittentin. Die angegebene Mietrendite errechnet sich auf Basis der im Mietvertrag zur entsprechenden Immobilie vereinbarten Miete, abzüglich etwaiger Rücklagen, einer Management Fee, einer Liquiditätsrücklage und gegebenenfalls sonstigen zur ordentlichen Verwaltung der jeweiligen Immobilie notwendigen Maßnahmen und Kosten. Die Mietrenditen sind in Prozent pro Jahr angegeben.</span>',
      },
      threeA: {
        sm: "<span>Die über Brickwise vermittelten Finanzprodukte sind mit erheblichen Risiken verbunden und können zum vollständigen Verlust des eingesetzten Kapitals führen. Die grundbücherliche Sicherheit in Höhe des Ausgabepreises der digitalen Immobilienanteile wird nach der Emission beantragt.</span>",
      },
      fourA: {
        sm: "<span>Inhaber:innen tokenisierter Genussscheine können andere Investor:innen über den von Brickwise angebotenen Marktplatz dazu einladen, Angebote zum Kauf oder Verkauf der tokenisierten Genussscheine zu legen. Es ist nicht garantiert, dass deine Anteile von anderen Investor:innen gekauft werden.</span>",
        xl: "<span>Inhaber:innen tokenisierter Genussscheine können andere Investor:innen über den von Brickwise angebotenen Marktplatz dazu einladen, Angebote zum Kauf oder Verkauf der tokenisierten Genussscheine zu legen. Es ist nicht gewährleistet, dass tokenisierte Genussscheine zum gewünschten oder einem anderen Zeitpunkt wieder verkauft werden können. Der Verkauf tokenisierter Genussscheine erfordert eine(n) Käufer:in, der/die bereit ist, den vom/von der Verkäufer:in angebotenen Verkaufspreis zzgl. Transaktionskosten zu bezahlen. In diesem Sinne kann nicht ausgeschlossen werden, dass tokenisierte Genussscheine nicht wieder verkauft werden können.</span>",
      },
    },
    n = document.getElementsByClassName("legal-tooltip-span-1"),
    i = Array.from(n);
  for (const e in i) i[e].addEventListener("click", r);
  let t,
    s = !1;
  function r() {
    if (((t = this), !s)) {
      let n = e[this.dataset.tooltip],
        i = document.createElement("div");
      i.classList.add("tip-wrapper");
      let t = document.createElement("div");
      t.classList.add("backgroundClickDiv"), t.addEventListener("click", d), i.appendChild(t);
      let r = document.createElement("div");
      r.classList.add("tooltip");
      let l = document.createElement("div");
      if (
        (l.classList.add("legal-text-short"),
        (l.innerHTML = `<span>${n.sm}</span>`),
        r.appendChild(l),
        n.xl)
      ) {
        let e = document.createElement("a");
        e.classList.add("read-more-legal-link"),
          (e.innerText = "Mehr lesen"),
          e.addEventListener("click", () => {
            !(function (e, n, i) {
              let t = document.createElement("div");
              t.classList.add("close-div");
              let s = document.createElement("img");
              (s.src =
                "https://assets.website-files.com/62414ac6ad5d1a1acc6c486a/62414ac6ad5d1af4586c4941_close-icon-popup.b57e096c.svg"),
                (s.loading = "lazy"),
                (s.alt = "close icon"),
                s.classList.add("info-close-button"),
                s.addEventListener("click", () => {
                  d();
                }),
                t.appendChild(s);
              let r = document.createElement("div");
              r.classList.add("legal-text-long"),
                (r.innerHTML = `<span>${n}</span>`),
                e.replaceChildren(t, r),
                a();
              let l = document.createElement("div");
              l.classList.add("mobile-full-screen-container");
              let c = document.createElement("div");
              c.classList.add("mobile-close-div");
              let o = document.createElement("div");
              o.classList.add("legal-text-long-mobile"), (o.innerHTML = n);
              let u = document.createElement("img");
              (u.src =
                "https://assets.website-files.com/62414ac6ad5d1a1acc6c486a/6298c6a820fdf2b1fa160c41_Back_icon.svg"),
                (u.loading = "lazy"),
                (u.alt = "back button"),
                u.addEventListener("click", () => {
                  d();
                }),
                c.appendChild(u),
                l.appendChild(c),
                l.appendChild(o);
              let m = document.createElement("div");
              m.classList.add("info-close-text"),
                m.addEventListener("click", () => {
                  d();
                });
              let h = document.createElement("img");
              (h.src =
                "https://assets.website-files.com/62414ac6ad5d1a1acc6c486a/6298c8a9765f876f7dcd779b_info_white.svg"),
                (h.loading = "lazy"),
                (h.alt = "back button");
              let g = document.createElement("p");
              g.classList.add("mobile-close-text"),
                (g.innerText = "Information Schließen"),
                m.appendChild(h),
                m.appendChild(g),
                l.appendChild(m),
                i.appendChild(l);
            })(r, n.xl, i);
          }),
          l.appendChild(e);
      }
      let o = document.createElement("div");
      o.classList.add("triangle-grey-downwards"),
        i.appendChild(r),
        i.appendChild(o),
        document.getElementsByTagName("body")[0].appendChild(i),
        (s = !0),
        document.addEventListener("scroll", c),
        a();
    }
  }
  function d() {
    document.getElementsByClassName("tip-wrapper")[0].remove(),
      (s = !1),
      document.removeEventListener("scroll", c);
  }
  function a() {
    let e = t.getBoundingClientRect(),
      n = document.getElementsByClassName("triangle-grey-downwards")[0];
    const i = e.width / 2;
    (n.style.left = e.x - n.offsetWidth / 2 + i + "px"),
      (n.style.top = e.y - n.offsetHeight + "px");
    let s = document.getElementsByClassName("tooltip")[0];
    s.getBoundingClientRect(),
      (s.style.top = e.y - s.offsetHeight - n.offsetHeight + "px"),
      e.x - s.offsetWidth / 2 < 20
        ? (s.style.left = "20px")
        : e.x + s.offsetWidth / 2 > window.innerWidth ||
          (s.style.left = e.x - s.offsetWidth / 2 + "px"),
      e.x + s.offsetWidth / 2 > window.innerWidth &&
        ((s.style.right = "20px"), (s.style.left = null)),
      e.x,
      s.offsetWidth,
      window.innerWidth;
  }
  let l = !1;
  function c(e) {
    l ||
      (window.requestAnimationFrame(function () {
        a(), (l = !1);
      }),
      (l = !0));
  }
});
