export default () => {
  function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  // unbind webflow form handling
  if (window.location.pathname !== "/landing/brickwise-affiliate-partner-program" && window.location.pathname !== "/landing/brickwise-webinar-register" && window.location.pathname !== "/landing/brickwise-whitepaper---burgerbeteiligung-an-erneuerbaren-energien") $(document).off("submit");
  $("form").submit(function (evt) {
    let searchParams = new URLSearchParams(window.location.search);
    // special webinar handling
    if (window.location.pathname === "/landing/brickwise-affiliate-partner-program" || window.location.pathname === "/landing/brickwise-webinar-register" || window.location.pathname === "/landing/brickwise-whitepaper---burgerbeteiligung-an-erneuerbaren-energien") {

      if (window.location.pathname === "/landing/brickwise-whitepaper---burgerbeteiligung-an-erneuerbaren-energien") {
        window.open("https://cdn.brickwise.at/marketing/Whitepaper/Whitepaper_Brickwise_Buergerbeteiligung.pdf")
      }
      // Do nothing
    } else {
      evt.preventDefault();
      if (window.location.pathname === "/landing/brickwise-community") {
        var email = document.getElementById("email-bonus").value;
        var xhr = new XMLHttpRequest();
        xhr.open(
          "POST",
          "https://api.app.brickwise.at/api/v1/bonus/bonusCode/useBonusWithEmail",
          true
        );

        //Send the proper header information along with the request
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        xhr.onreadystatechange = function () {
          document.getElementById("email-field-community").style.display = "none";
          document.getElementById("success-field-community").style.display = "block";
          // Call a function when the state changes.
          if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            // Do something after posting the form.
          }
        };

        xhr.send(`email=${email}&bonusCode=BrickwiseCommunityPaternion`);
      } else if (window.location.pathname.includes("/affiliate/")) {
        // do affiliate handling
        var firstName = document.getElementById("firstName-affiliate").value;
        var lastName = document.getElementById("lastName-affiliate").value;
        var email = document.getElementById("email-affiliate").value;
        var affiliateLink = document.getElementById("link-affiliate").innerHTML;

        var xhr = new XMLHttpRequest();
        xhr.open(
          "POST",
          "https://api.app.brickwise.at/api/v1/affiliate/affiliatedUser",
          true
        );

        //Send the proper header information along with the request
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        xhr.onreadystatechange = function () {
          document.getElementById("firstName-affiliate").value = "";
          document.getElementById("lastName-affiliate").value = "";
          document.getElementById("email-affiliate").value = "";
          // Call a function when the state changes.
          if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            // Do something after posting the form.
            document.getElementById("txt-affiliate-success").innerHTML = `<b>Gratuliere!</b> Wir haben dir eine Einladung an ${email} gesendet.`
            document.getElementById("div-affiliate-success").style.display = "block";

            setTimeout(() => { document.getElementById("div-affiliate-success").style.display = "none"; }, 3000)
          } else if (this.readyState === XMLHttpRequest.DONE && this.status !== 200) {
            // Do something after posting the form.
            document.getElementById("txt-affiliate-error").innerHTML = "<b>Oje!</b> Das hat leider nicht geklappt. Kontrolliere bitte deine Eingabe."
            document.getElementById("div-affiliate-error").style.display = "block";

            setTimeout(() => { document.getElementById("div-affiliate-error").style.display = "none"; }, 3000)
          }
        };

        xhr.send(`firstName=${firstName}&lastName=${lastName}&email=${email}&affiliateLink=${affiliateLink}`);
      } else {
        // Load Segment if UTM params
        if (
          searchParams.has("utm_source") ||
          searchParams.has("utm_medium") ||
          searchParams.has("utm_campaign") ||
          searchParams.has("gclid") ||
          searchParams.has("fbclid")
        ) {
          !(function () {
            var analytics = (window.analytics = window.analytics || []);
            if (!analytics.initialize)
              if (analytics.invoked)
                window.console &&
                  console.error &&
                  console.error("Segment snippet included twice.");
              else {
                analytics.invoked = !0;
                analytics.methods = [
                  "trackSubmit",
                  "trackClick",
                  "trackLink",
                  "trackForm",
                  "pageview",
                  "identify",
                  "reset",
                  "group",
                  "track",
                  "ready",
                  "alias",
                  "debug",
                  "page",
                  "once",
                  "off",
                  "on",
                  "addSourceMiddleware",
                  "addIntegrationMiddleware",
                  "setAnonymousId",
                  "addDestinationMiddleware",
                ];
                analytics.factory = function (e) {
                  return function () {
                    var t = Array.prototype.slice.call(arguments);
                    t.unshift(e);
                    analytics.push(t);
                    return analytics;
                  };
                };
                for (var e = 0; e < analytics.methods.length; e++) {
                  var key = analytics.methods[e];
                  analytics[key] = analytics.factory(key);
                }
                analytics.load = function (key, e) {
                  var t = document.createElement("script");
                  t.type = "text/javascript";
                  t.async = !0;
                  t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
                  var n = document.getElementsByTagName("script")[0];
                  n.parentNode.insertBefore(t, n);
                  analytics._loadOptions = e;
                };
                analytics._writeKey = "11hShZIxV3ESPvva44LGIXydWVonqj2L";
                analytics.SNIPPET_VERSION = "4.15.3";
                analytics.load("11hShZIxV3ESPvva44LGIXydWVonqj2L");
                analytics.page();
                console.log("segment loaded");
              }
          })();
          // Timeout of recommended 300ms before sending form (segment documentation)
          setTimeout(() => {
            if (id === 'pop-up-email-form') {
              popUpFormHandling()
            } else {
              formHandling();
            }
          }, 300);
        } else {
          var form = $(this);
          var id = form.attr('id');
          console.log('Form handling', id)
          if (id === 'pop-up-email-form') {
            popUpFormHandling()
          } else {
            formHandling();
          }
        }
      }
    }
    

    function formHandling() {
      var emailfields = 6;
      var email;
      var emailHtml;
      for (let i = 1; i <= emailfields; i++) {
        if (document.getElementById("email-" + i)) {
          const emailField = document.getElementById("email-" + i);
          if (emailField.value) {
            emailHtml = document.getElementById("email-" + i);
            email = emailHtml.value;
          }
        }
      }

      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.app.brickwise.at/api/v1/utils/millionaire", true);

      //Send the proper header information along with the request
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
          let response = JSON.parse(this.responseText);
          if (response.data && response.data.userId)
            dataLayer.push({
              event: "Brickwise_UserId",
              brickwiseUserId: response.data.userId,
            });
          document.cookie = "brickwiseUserId=" + response.data.userId;
          dataLayer.push({ event: "lead_form_signup" });
        }
      };

      let anonymousId = getCookie("ajs_anonymous_id") || "";
      let googleClientId = getCookie("_ga") || "cookie_not_set";
      let facebookClientId = getCookie("_fbp") || "cookie_not_set";
      let affiliateSubid = searchParams.has("s_id")
        ? searchParams.get("s_id")
        // If we have more affilaites in the future, just rename finance ads cookie to affiliate / subid. This will also avoid having serveral affiliates earn credit for one conversion ;).
        : getCookie("financeAds") ?? false;

      if (searchParams.has("fbclid")) {
        searchParams.set("fbclid", `fb.1.${+new Date()}.${searchParams.get("fbclid")}`);
        !searchParams.has("utm_source") ? searchParams.set("utm_source", "facebook") : '';
        !searchParams.has("utm_medium") ? searchParams.set("utm_medium", "cpc") : '';
        !searchParams.has("utm_campaign") ? searchParams.set("utm_campaign", "fbclid"): '';
      }

      const paramAssembler = searchParams.toString();

      xhr.send(
        `email=${email}&${paramAssembler}&anonymousId=${anonymousId}&googleClientId=${googleClientId}&facebookClientId=${facebookClientId}&sid=${affiliateSubid}&path=${location.protocol}//${location.host}${location.pathname}`
      );
      document.getElementById("email-sent-modal").style.display = "block";
      document.getElementById(
        "email-sent-text"
      ).innerHTML = `Wir haben dir eine Bestätigungsnachricht an ${email} gesendet.`;

      emailHtml.value = "";

      // e-paper handling 
      if (window.location.pathname === "/landing/brickwise-epaper") {
        window.open("https://cdn.brickwise.at/Brickwise_Warum-dein-Investment-die-Welt-veraendert.pdf")
      }
    }

    function popUpFormHandling() {
      var emailForm = document.getElementById("pop-up-email")
      if (!emailForm) return

      var email = emailForm.value

      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.app.brickwise.at/api/v1/utils/millionaire", true);

      //Send the proper header information along with the request
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
          let response = JSON.parse(this.responseText);
          if (response.data && response.data.userId)
            dataLayer.push({
              event: "Brickwise_UserId",
              brickwiseUserId: response.data.userId,
            });
          document.cookie = "brickwiseUserId=" + response.data.userId;
          dataLayer.push({ event: "lead_form_signup" });
        }
      };

      let anonymousId = getCookie("ajs_anonymous_id") || "";
      let googleClientId = getCookie("_ga") || "cookie_not_set";
      let facebookClientId = getCookie("_fbp") || "cookie_not_set";
      let affiliateSubid = searchParams.has("s_id")
        ? searchParams.get("s_id")
        // If we have more affilaites in the future, just rename finance ads cookie to affiliate / subid. This will also avoid having serveral affiliates earn credit for one conversion ;).
        : getCookie("financeAds") ?? false;

      if (searchParams.has("fbclid")) {
        searchParams.set("fbclid", `fb.1.${+new Date()}.${searchParams.get("fbclid")}`);
        !searchParams.has("utm_source") ? searchParams.set("utm_source", "facebook") : '';
        !searchParams.has("utm_medium") ? searchParams.set("utm_medium", "cpc") : '';
        !searchParams.has("utm_campaign") ? searchParams.set("utm_campaign", "fbclid"): '';
      }

      !searchParams.has("utm_source") ? searchParams.set("utm_source", "pop-up") : ''
      !searchParams.has("utm_medium") ? searchParams.set("utm_medium", "landingpage"): ''
      !searchParams.has("utm_campaign") ? searchParams.set("utm_campaign", "timed-pop-up"): ''

      const paramAssembler = searchParams.toString();

      xhr.send(
        `email=${email}&${paramAssembler}&anonymousId=${anonymousId}&googleClientId=${googleClientId}&facebookClientId=${facebookClientId}&sid=${affiliateSubid}&path=${location.protocol}//${location.host}${location.pathname}`
      );
      document.getElementById("pop-up-wrapper").style.display = "none";
      
    }

    function webinarFormHandling() {
      var emailForm = document.getElementById("webinar-email-double")
      var emailForm2 = document.getElementById("webinar-email")

      console.log('Here', emailForm, emailForm2)
      if (!emailForm) return

      var email = emailForm.value

      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.app.brickwise.at/api/v1/utils/millionaire", true);

      //Send the proper header information along with the request
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
          let response = JSON.parse(this.responseText);
          if (response.data && response.data.userId)
            dataLayer.push({
              event: "Brickwise_UserId",
              brickwiseUserId: response.data.userId,
            });
          document.cookie = "brickwiseUserId=" + response.data.userId;
          dataLayer.push({ event: "lead_form_signup" });
        }
      };

      let anonymousId = getCookie("ajs_anonymous_id") || "";
      let googleClientId = getCookie("_ga") || "cookie_not_set";
      let facebookClientId = getCookie("_fbp") || "cookie_not_set";
      let affiliateSubid = searchParams.has("s_id")
        ? searchParams.get("s_id")
        // If we have more affilaites in the future, just rename finance ads cookie to affiliate / subid. This will also avoid having serveral affiliates earn credit for one conversion ;).
        : getCookie("financeAds") ?? false;

      !searchParams.has("utm_source") ? searchParams.set("utm_source", "form") : ''
      !searchParams.has("utm_medium") ? searchParams.set("utm_medium", "landingpage"): ''
      !searchParams.has("utm_campaign") ? searchParams.set("utm_campaign", "webinar-form"): ''

      const paramAssembler = searchParams.toString();

      xhr.send(
        `email=${email}&${paramAssembler}&anonymousId=${anonymousId}&googleClientId=${googleClientId}&facebookClientId=${facebookClientId}&sid=${affiliateSubid}&path=${location.protocol}//${location.host}${location.pathname}`
      );      
    }
  });
};
